import { AxiosError } from "axios";
import { handleError } from "../shared";
import { Api } from ".";

export class BeaconApi {
  getListByParams = async (params: any) => {
    try {
      const response = await Api.post("/api/v1/beacon/list", {
        target_id: params.target_id,
        type: params.type,
        search_phrase: params.search_phrase,
      }).then((response: { data: any }) => {
        return response.data;
      });
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  };

  getBeaconTypes = async () => {
    try {
      const response = await Api.get("/api/v1/beacon/type/list").then(
        (response: any) => {
          return response.data;
        }
      );

      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  };

  getUserMsgs = async (input: any) => {
    try {
      const response = await Api.get(
        `/api/v1/beacon/message/autocomplete`,
        input
      ).then((response: { data: any }) => {
        return response.data;
      });
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  };

  delete = async (params: string) => {
    try {
      const response = await Api.remove(`/api/v1/beacon/${params}`).then(
        (response) => {
          return response.data;
        }
      );
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  };

  update = async (params: any) => {
    try {
      const response = await Api.put(`/api/v1/beacon/${params.id}`, {
        comment: params.commentText,
      }).then((response) => {
        return response.data;
      });
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  };

  getMesagesUpdates = async (params: any) => {
    try {
      const response = await Api.get(
        `/api/v1/beacon/${params.beacon_id}/message/${params.message_id}`
      ).then((response: { data: any }) => {
        return response.data;
      });
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  };

  addMessage = async (params: any) => {
    try {
      const response = await Api.post(`/api/v1/beacon/${params.id}/message`, {
        request: params.request,
        recurrence_interval: params.recurrence_interval,
        detach: params.detach,
        scheduled_for: params.scheduled_for,
      }).then((response) => {
        return response.data;
      });
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  };

  getMessages = async (params: string) => {
    try {
      const response = await Api.get(`/api/v1/beacon/${params}/messages`).then(
        (response: { data: any }) => {
          return response.data;
        }
      );
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  };

  getBeaconById = async (id: string) => {
    try {
      const response = await Api.get(`/api/v1/beacon/${id}`).then(
        (response: { data: any }) => {
          return response.data;
        }
      );
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  };

  deleteMessages = async (params: any) => {
    try {
      const response = await Api.remove(
        `/api/v1/beacon/${params.beacon_id}/message/${params.id}`
      ).then((response) => {
        return response.data;
      });
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  };

  getAutocomplete = async (input: any) => {
    try {
      const response = await Api.get(
        `/api/v1/beacon/message/autocomplete`,
        input
      ).then((response: { data: any }) => {
        return response.data;
      });
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  };

  uploadFile = async (bid: string, file: any) => {
    try {
      const response = await Api.uploadFile(
        `/api/v1/beacon/${bid}/upload`,
        file
      ).then((response) => {
        return response;
      });
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  };

  getHelp = async (type: string) => {
    try {
      const response = await Api.get(`/api/v1/beacon/${type}/help`).then(
        (response) => {
          return response.data;
        }
      );
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  };
}
