
import { Tooltip } from "antd";
const gsnetcat = require('../../../images/kitty.png')
const proxy = require('../../../images/proxy.png')
const mushroom = require('../../../images/mushroom.png')
const revshell = require('../../../images/reverse.png')
const question = require('../../../images/question.png')

export type SessionType = "gsnetcat" | "mycelium" | "proxy" | "revshell" | unknown;

export const SessionTypeIcon = (sessionType: SessionType, index: number): JSX.Element => {
    const TypeIcon: JSX.Element = (() => {
        switch (sessionType) {
            case 'gsnetcat': return <img alt="" src={gsnetcat} style={{ color: 'green' }} />;
            case 'mycelium': return <img alt="" src={mushroom} style={{ color: 'red' }} />;
            case 'proxy': return <img alt="" src={proxy} style={{ color: 'black' }} />;
            case 'revshell': return <img alt="" src={revshell} style={{ color: 'blue' }} />;
            default: return <img alt="" src={question} style={{ color: 'pink' }} />;
        }
    })();

    TypeIcon.props.style.width = '25px';
    TypeIcon.props.style.marginRight = '5px';

    return <Tooltip title={typeof sessionType === 'string' ? sessionType : "other"} key={`${sessionType}_${(new Date()).getTime() * 3}`} color={TypeIcon.props.style.color}>
        {TypeIcon}
    </Tooltip>;
};

