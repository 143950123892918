import {
  CheckOutlined,
  CodeOutlined,
  CommentOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  MinusOutlined,
} from "@ant-design/icons";
import { Badge, Tooltip } from "antd";
import { addComment, deleteRecord, showInfoModal } from ".";
import moment from "moment";

export const render_actions = (
  record: any,
  setIsRerender?: (isRerender: boolean) => void
) => {
  const messagesColor =
    record.last_message_status === "success"
      ? "green"
      : record.last_message_status === "error"
      ? "red"
      : "grey";

  return (
    <div className="action-btns">
      <InfoCircleOutlined
        title="Info"
        style={{ color: "rgb(74, 74, 245)" }}
        onClick={() => showInfoModal(record)}
      />
      <CommentOutlined
        title="Comment"
        style={{ color: "#b980e1" }}
        onClick={() => {
          addComment(record, setIsRerender);
        }}
      />
      <Badge count={record.unprocessed_messages}>
        <a href={`/ui/beacon/${record.id}/messages`}>
          <CodeOutlined
            title="Commands"
            style={{ color: messagesColor, fontSize: "19px" }}
          />
        </a>
      </Badge>
      <DeleteOutlined
        onClick={() => {
          deleteRecord(record, setIsRerender);
        }}
        title="Delete"
        style={{ color: "red" }}
      />
    </div>
  );
};

export const highlightRow = (record: any) => {
  let time =
    Math.floor(new Date(record.next_expected_emit)?.getTime() - Date.now()) /
    (1000 * 60);
  let cl =
    time > -1 ? "#20a20f" : time <= -1 && time >= -3 ? "#ffa200" : "#f94343";
  return time !== null && record.next_expected_emit ? (
    <span style={{ color: cl }}>
      <b>{moment(record.next_expected_emit).format("MMM DD YYYY, HH:mm")} </b>
    </span>
  ) : (
    <></>
  );
};

export const file_link = (value: any, record: any) => {
  return (
    <Tooltip placement="topLeft" title={record.storage_url}>
      <a href={record.storage_url} target="_blank" rel="noreferrer">
        Files
      </a>
    </Tooltip>
  );
};

export const last_msg = (text: any, record: any) =>
  record.last_message_status === "success" ? (
    <CheckOutlined style={{ color: "green", fontSize: "19px" }} />
  ) : (
    <MinusOutlined style={{ color: "grey", fontSize: "19px" }} />
  );
