import React from 'react';
import { TargetNote, renderHostLinks } from '../../../shared';
import { Card, Empty, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import cls from '../notes.module.css';
import moment from 'moment';


type Props = {
    id: string | undefined,
    notes: TargetNote[],
    deleteNote: (item: TargetNote) => void
}

const NodeCards: React.FC<Props> = ({ id, notes, deleteNote }) => {

    return <>{notes.length > 0 ? notes.map((i, index) => {
        return <Card key={index}
            title={<><a href={`/ui/target/${id}/note/${i.id}`}
                className={cls.titleLink}>{i.title}</a>
                <Tooltip title="Delete note" color='red'>
                    <DeleteOutlined style={{ marginTop: "3px" }}
                        onClick={() => { deleteNote(i) }}
                        className={cls.deleteBtn} />
                </Tooltip></>}
            className={cls.noteCard}
            bordered={true}
            style={{ width: 400, margin: '10px' }}
        ><> <b>Last updated by:</b> {i.user} </><br />
            <><b>Version:</b> {i.version} </><br />
            <><b>Created:</b> {moment(i.created_at).format('MMM DD YYYY, HH:mm')} </><br />
            <><b>Updated:</b> {moment(i.updated_at).format('MMM DD YYYY, HH:mm')} </><br />
            <><b>Hosts:</b> {i.hosts.length > 0 ? renderHostLinks(i.hosts) : 'There are no hosts'}</>
        </Card>
    }) : <div className={cls.nodesList}> <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></div>}
    </>
}

export default NodeCards