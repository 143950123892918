import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useBeaconState } from "./Store";

import { FloatButton, Table, Tooltip } from "antd";

import { Get_beacon_columns } from "./ui";
import { AddMultiMsgs } from "./MessagesTable/ui";
import {
  Beacon,
  getCookie,
  setCookie,
  Filters,
  filterData,
  pagination_options,
} from "../../shared";
import {
  SearchTargets,
  TableSettings,
  Toggle,
  ColumnSelector,
  TargetContext,
  get_rowSelection,
} from "../shared";
import { MenuUnfoldOutlined } from "@ant-design/icons";

type Props = {
  beacons?: Beacon[];
  setIsRerender?: (isRerender: boolean) => void;
  colsType?: string;
  id?: string;
};

const BeaconTable: React.FC<Props> = ({
  beacons,
  setIsRerender,
  colsType,
  id,
}) => {
  const token = getCookie("token") as string;
  const navi = useNavigate();
  const { targetsList } = React.useContext(TargetContext)!;
  const [state, actions] = useBeaconState();

  const handleFilter = (data: Beacon[]) => {
    if (data) {
      const filters: Filters = { ...state.filteredInfo };
      const filteredData = filterData(data, filters);
      actions.setFilteredData(filteredData as Beacon[]);
    }
  };

  const handleChange = (sorter: any) => {
    if (!beacons && !id) {
      setCookie(
        "beacon_columns_sorter",
        `${sorter.columnKey},${sorter.order}`,
        365
      );
      actions.changeSelectedColumnsSorting(sorter);
    }
  };

  useEffect(() => {
    let interval: string | number | NodeJS.Timeout | undefined;
    if (state.isActive && !beacons && !id && !state.isLoading) {
      interval = setInterval(() => {
        actions.setTableData(beacons);
      }, 10 * 1000);
      return () => {
        clearInterval(interval);
      };
    } else {
      return () => {
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isActive, state.isLoading]);

  useEffect(() => {
    if (!beacons && !id) {
      actions.setTypesList();
      handleFilter(state.tabledata);
      document.title = "Beacons / BartUI ";
    }
    actions.setTableData(beacons, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    state.checkSwith && actions.setTableData(beacons, id);
    actions.setCheckSwith(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.checkSwith]);

  useEffect(() => {
    handleFilter(state.tabledata);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.filteredInfo, state.tabledata]);

  return !token ? (
    <></>
  ) : (
    <div
      className={beacons || id ? "" : "messages_table"}
      style={{ position: "relative" }}
    >
      {!beacons && !id ? (
        <>
          <SearchTargets
            getData={actions.setTableData}
            typesList={state.typesList}
            type={"beacon"}
            setMulti={
              state.selectedRowKeys.length > 0 ? actions.setMulti : undefined
            }
            tableData={state.filteredData}
          />
          <TableSettings>
            <Toggle
              active={state.isActive}
              onClick={actions.setTableData}
              loading={state.isLoading}
              setActive={actions.setActive}
            ></Toggle>
            <ColumnSelector
              tabledata={state.tabledata}
              targetsList={targetsList}
              columns={Get_beacon_columns(
                targetsList,
                colsType,
                actions.setCheckSwith
              )}
              selected_values={
                state.selectedColumns?.map((item) =>
                  item.title?.toString()
                ) as string[]
              }
              cookie_name="beacon_columns"
              setSelectedColumns={actions.setSelectedColumns}
            />
          </TableSettings>
        </>
      ) : id ? (
        <SearchTargets
          getData={() => actions.setTableData(beacons, id)}
          typesList={state.typesList}
          type={"target_beacon"}
          setMulti={
            state.selectedRowKeys.length > 0 ? actions.setMulti : undefined
          }
          tableData={state.filteredData}
        />
      ) : (
        <></>
      )}
      {beacons ? (
        state.selectedRowKeys.length > 0 && (
          <>
            {" "}
            <Tooltip title="Add messages">
              <MenuUnfoldOutlined
                className="multi_btn"
                onClick={() => {
                  actions.setMulti!(true);
                }}
              />
            </Tooltip>
          </>
        )
      ) : (
        <></>
      )}
      <Table
        scroll={
          (beacons || id) && colsType === "hostInfo" ? { x: 1600 } : { x: 2000 }
        }
        className={beacons || id ? "credsTable" : "netCatTable"}
        columns={
          beacons || id
            ? Get_beacon_columns(targetsList, colsType, setIsRerender).columns
            : state.selectedColumns
        }
        dataSource={beacons ? beacons : state.tabledata}
        pagination={
          (beacons && beacons.length < 50) ||
          (id && state.tabledata.length < 50)
            ? false
            : pagination_options
        }
        onChange={(_, filter, sorter, extra) => {
          actions.setFilteredInfo(filter);
          handleChange(sorter);
          actions.setFilteredData(extra.currentDataSource);
        }}
        rowKey="id"
        onRow={(record) => {
          return {
            onDoubleClick: (event) => {
              navi(`/ui/beacon/${record.id}/messages`);
            },
          };
        }}
        rowSelection={get_rowSelection(state, actions)}
      />
      <FloatButton.BackTop />
      <AddMultiMsgs getData={() => actions.setTableData(beacons, id)} />
    </div>
  );
};
export default BeaconTable;
