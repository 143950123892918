import { Beacon, BeaconMessage } from "../../../../shared";
import moment from "moment";
import {
  DeleteOutlined,
  LoadingOutlined,
  RetweetOutlined,
  VerticalAlignBottomOutlined,
} from "@ant-design/icons";
import { deletemessage, download } from "../actions";
import { Tooltip } from "antd";
import { useMessagesState } from "../Store";

type Props = {
  item: BeaconMessage;
  beacon: Beacon | undefined;
  index: number;
};

export const DateSection = ({ item, beacon, index }: Props) => {
  const [state, actions] = useMessagesState();
  const filename = `${beacon?.target_host.machine_id.substring(
    0,
    6
  )}_${Math.round(new Date(item.created_at).getTime() / 1000)}_${index}_${
    item.request.indexOf(" ") > 0
      ? item.request.substring(0, item.request.indexOf(" ")).replaceAll(" ", "")
      : item.request.replaceAll(" ", "")
  }.txt`;

  return (
    <div className="date_section_top">
      <div className="date_section">
        {item.scheduled_for ? (
          <span>
            <b>Scheduled for: </b>
            {moment(item.scheduled_for).format("MMM DD YYYY, HH:mm")}
          </span>
        ) : (
          <></>
        )}
        {item.recurrence_interval ? (
          <span>
            <b>Recurrence interval: </b>
            {item.recurrence_interval}
          </span>
        ) : (
          <></>
        )}
        <p style={{ margin: "0 0 5px" }}>
          <b>Created: </b>
          {moment(item.created_at).format("MMM DD YYYY, HH:mm")}
        </p>
        <p style={{ margin: 0 }}>
          <b>Processed: </b>
          {item.processed_at ? (
            <>{moment(item.processed_at).format("MMM DD YYYY, HH:mm")}</>
          ) : (
            <>
              In progress...
              <LoadingOutlined
                style={{ marginLeft: "5%", fontSize: "25px" }}
                className="reload-btn"
              />
            </>
          )}
        </p>
      </div>

      {item.reply && item.reply.length > 0 && (
        <Tooltip title="Download" color="green">
          <VerticalAlignBottomOutlined
            onClick={(event) => {
              event.preventDefault();
              download(filename, `${item.request} \n\n${item.reply}`);
            }}
            style={{
              float: "right",
              color: "green",
              margin: "0 25px 0 5%",
              fontSize: "19px",
              border: "1px solid green",
              borderRadius: "4px",
              padding: "1px",
              alignSelf: "center",
            }}
          />
        </Tooltip>
      )}

      <Tooltip title="Repeat" color="#a6a6f1">
        <RetweetOutlined
          style={{
            float: "right",
            color: "#a6a6f1",
            marginRight: "20px",
            fontSize: "19px",
            border: "1px solid #a6a6f1",
            borderRadius: "4px",
            padding: "1px",
            alignSelf: "center",
          }}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            !state.commands.includes(item.request) &&
              actions.setCommands([...state.commands, item.request]);
          }}
        />
      </Tooltip>
      <Tooltip title="Delete" color="red">
        <DeleteOutlined
          onClick={() => {
            deletemessage(item);
          }}
          style={{
            borderRadius: "4px",
            alignSelf: "center",
            float: "right",
            color: "red",
            margin: "0 25px 0 5%",
            fontSize: "19px",
            padding: "2px",
            border: "1px solid red",
          }}
        />
      </Tooltip>
    </div>
  );
};
