import { AutoComplete, DatePicker, Form, Input, Modal } from 'antd'
import FormItem from 'antd/es/form/FormItem'
import TextArea from 'antd/es/input/TextArea'
import { useEffect, useState } from 'react'
import { BeaconApi } from '../../../../Api'
import '../styles.css'
import { AutocompleteOption, onlyUnique } from '../../../../shared'
import Checkbox from 'antd/es/checkbox/Checkbox'
import { useBeaconState } from '../../Store';

const BEACON_CLIENT = new BeaconApi();
type Props = {
  getData: () => Promise<void>
}

export const AddMultiMsgs = ({ getData }: Props) => {
  const [form] = Form.useForm(undefined);
  const [options, setOptions] = useState<AutocompleteOption[]>([]);
  const [commands, setCommands] = useState<string[]>([]);
  const [beaconState, beaconActions] = useBeaconState();

  const setUserOptions = async () => {
    let optionsList: string[] = await BEACON_CLIENT.getUserMsgs({ input: undefined });
    setOptions(optionsList?.filter(onlyUnique).map(option => ({ value: option })));
  }

  const addMessage = async () => {

    form.validateFields().then(async (result: any) => {
      beaconState.selectedRowKeys.forEach(async (id: string) => {
        let coms = form.getFieldValue('msg').split('\n')
        for (let item of coms) {
          if (item.length > 0) {
            await BEACON_CLIENT.addMessage({ id: id, request: item, recurrence_interval: result.recurrence_interval, scheduled_for: result.scheduled_for?.format(), detach: result.detach })
          }
        };
      })
      getData();
      form.resetFields();
      setCommands([])
    }, (error) => {
      Modal.error({ title: 'Empty message input' });
    })
  }

  const filterAutocomplite = (inputValue: string, option: { value: string } | undefined) => {
    let cmds = inputValue?.split('\n');
    return option?.value.toUpperCase().indexOf(cmds[cmds.length - 1].toUpperCase()) !== -1
  }


  const handleSelect = (value: any) => {
    commands.push(value);
    setCommands(commands);
    form.setFieldValue('msg', commands.join('\n'));
  }

  const handleEnter = () => {
    let items = form.getFieldValue("msg")?.split('\n');
    setCommands(items?.filter((i: any, index: number) => i.length > 0 && index !== items.length - 1));
  }

  const handleClear = () => {
    form.resetFields()
    setCommands([]);
  }

  const getAutoOptions = async () => {
    let cmds = form.getFieldValue('msg')?.split('\n');
    let response: [] = await BEACON_CLIENT.getAutocomplete({ input: cmds?.length > 0 ? cmds[cmds.length - 1] : undefined })
    setOptions(response?.map((i: string) => { return { value: i } }));
  }

  useEffect(() => { if (beaconState.isMulti) { setUserOptions() } }, [beaconState.isMulti])

  useEffect(() => {
    if (beaconState.isMulti) {
      let timeout = setTimeout(() => {
        getAutoOptions()
      }, 500)
      return () => {
        clearTimeout(timeout)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.getFieldValue('msg')])

  return (
    <Modal width={'800px'}
      title="Add messages to selected beacons"
      open={beaconState.isMulti}
      okText="Submit"
      onOk={() => { addMessage(); beaconActions.setMulti(false) }}
      onCancel={() => { beaconActions.setMulti(false); form.resetFields(); setCommands([]) }}
    >
      <Form form={form} className="multi_add_form" >
        <FormItem label="Messages" name={"msg"} style={{ width: "40%", margin: "0" }} rules={[{ required: true }]}>
          <AutoComplete options={options} style={{ width: "100%" }}
            allowClear
            onClear={handleClear}
            filterOption={filterAutocomplite}
            onSelect={handleSelect}>
            <TextArea placeholder='add new message'
              autoSize style={{ background: 'none' }}
              onChange={handleEnter}
              onKeyDown={(e) => {
                e.stopPropagation();
                if (e.key === "Enter" && e.shiftKey) { addMessage() }
              }}
            />
          </AutoComplete>
        </FormItem>
        <div className="flex_col" style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
          <FormItem label="Recurrence interval" name={"recurrence_interval"} rules={[{ max: 5 }]}>
            <Input placeholder='add recurrence interval' style={{ width: '228px' }} />
          </FormItem>
          <FormItem label={<label >Scheduled For</label>} className='cmd_form_item' name="scheduled_for" >
            <DatePicker
              style={{ marginLeft: '30px', width: '228px' }}
              format="DD/MM/YYYY HH:mm"
              showTime={{ use12Hours: false }}
            />
          </FormItem>
          <FormItem label="Detach " name="detach" valuePropName='checked'>
            <Checkbox style={{ color: 'white' }} />
          </FormItem>
        </div>
      </Form>
    </Modal>
  )
}

