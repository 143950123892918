import React from 'react'
import { TargetNote, renderHostLinks } from '../../../shared'
import { List, Tooltip } from 'antd'
import cls from '../notes.module.css';
import { DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';

type Props = {
    id: string | undefined,
    notes: TargetNote[],
    deleteNote: (item: TargetNote) => void
}

const NodesList: React.FC<Props> = ({ id, notes, deleteNote }) => {
    return (
        <List
            className={cls.nodesList}
            itemLayout="horizontal"
            dataSource={notes}
            renderItem={(i, index) => (
                <List.Item>
                    <List.Item.Meta
                        title={<><a href={`/ui/target/${id}/note/${i.id}`} className={cls.titleLink}>{i.title + ' >'} </a> <Tooltip title="Delete note" color='red'>
                            <DeleteOutlined style={{ marginTop: "3px" }} onClick={() => { deleteNote(i) }} className={cls.deleteBtn} />
                        </Tooltip></>}
                        description={<><> <b>Last updated by:</b> {i.user} </>/
                            <><b> Version:</b> {i.version} </>/
                            <><b> Created:</b> {moment(i.created_at).format('MMM DD YYYY, HH:mm')} </>/
                            <><b> Updated:</b> {moment(i.updated_at).format('MMM DD YYYY, HH:mm')} </><br />
                            <><b>Hosts:</b> {i.hosts.length > 0 ? renderHostLinks(i.hosts) : 'There are no hosts'}</> </>} />
                </List.Item>
            )}
        />
    )
}

export default NodesList