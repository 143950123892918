import { Collapse, CollapseProps } from "antd";
import { Connections, Logs_table, Patches } from ".";
import { HostInfoStore } from "../store";
import NetcatTablePage from "../../../NetcatTable/NetcatTablePage";
import BeaconTable from "../../../Beacon/BeaconTable";
import DataTable from "../../../Table/Table";
import CredentialsPage from "../../../CredentialsPage/CredentialsPage";
import HostCmdInfo from "../../../CMDPage/HostCmdInfo";
import { onCollapseChange } from "../actions";

export const InfoCollapse = (id: string) => {
  let state = HostInfoStore.storeState.getState();
  let logsItem =
    state.logs?.length > 0 ? (
      Logs_table(state.logs)
    ) : (
      <span className="empty_logs">There are no logs</span>
    );

  let items: CollapseProps["items"] = [
    {
      key: "100",
      label: "Remote sessions",
      children:
        state.hostDetails.target_host?.id === id ? (
          <NetcatTablePage
            sessions={state.hostDetails?.remote_sessions}
            setIsRerender={HostInfoStore.actions.setRerender}
            colsType="hostInfo"
          />
        ) : (
          <></>
        ),
    },
    {
      key: "101",
      label: "Beacons",
      children:
        state.hostDetails.target_host?.id === id ? (
          <BeaconTable
            beacons={state.hostDetails?.beacons}
            setIsRerender={HostInfoStore.actions.setRerender}
            colsType="hostInfo"
          />
        ) : (
          <></>
        ),
    },
    {
      key: "102",
      label: "Collection results",
      children:
        state.hostDetails.target_host?.id === id ? (
          <DataTable
            collection_results={state.hostDetails?.collection_results}
            setIsRerender={HostInfoStore.actions.setRerender}
            colsType="hostInfo"
            collection_result_analysis={
              state.hostDetails?.collection_result_analysis
            }
          />
        ) : (
          <></>
        ),
    },
    {
      key: "103",
      label: "Patched bins",
      children:
        state.hostDetails.target_host?.id === id ? (
          <Patches patched_bins={state.hostDetails?.patched_bins} />
        ) : (
          <></>
        ),
    },
    {
      key: "104",
      label: "Connection paths",
      children:
        state.hostDetails.target_host?.id === id ? (
          <Connections connections={state.hostDetails?.connection_paths} />
        ) : (
          <></>
        ),
    },
    {
      key: "105",
      label: "Credentials",
      children:
        state.hostDetails.target_host?.id === id ? (
          <CredentialsPage
            hostId={id}
            hostCreds={state.hostDetails.credentials}
            setRender={HostInfoStore.actions.setRerender}
            colsType="hostInfo"
          />
        ) : (
          <></>
        ),
    },
    {
      key: "106",
      label: "Logs",
      children: logsItem,
    },
    {
      key: "107",
      label: "Commands",
      children: <HostCmdInfo host_id={id} />,
    },
  ];

  return (
    <Collapse
      className="collapse"
      items={items}
      activeKey={state.hostDetails.target_host?.id === id ? state.keys : []}
      onChange={(key) => onCollapseChange(key, id)}
    />
  );
};
