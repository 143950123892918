import { ColumnsType } from "antd/es/table";
import { CheckOutlined } from "@ant-design/icons";

import {
  BeaconStatus,
  BeaconStatusIcon,
  Status,
  StatusIcon,
  renderSessionsIcons,
} from "../../shared";
import {
  columnSorter,
  getDateFilterOptions,
  getFilterOptions,
  onlyUnique,
  sortOptions,
  SearchTargetsModel,
  getCookie,
  renderDate,
  date_sorter,
  date_filter,
  sorter,
  render_tags,
  defaultFilter,
  customNotIncludesFilter,
} from "../../../shared";
import {
  client_filters,
  host_link,
  onClientFilter,
  render_actions,
  render_relay,
  target_link,
} from "../actions";
import { NetcatStore } from "../Store";

export const Get_netcat_columns = (
  colsType?: string,
  targetsList?: SearchTargetsModel[],
  setIsRerender?: (isRerender: boolean) => void
) => {
  const roles = getCookie("roles")?.split(",");
  let state = NetcatStore.storeState.getState();

  const columns = (
    [
      {
        title: "State",
        dataIndex: "status",
        key: "Status",
        render: (Status: Status) => StatusIcon(Status),
        width: 70,
        filters: getFilterOptions(
          state.tabledata?.map((item: any) => item?.status)?.filter(onlyUnique)
        ),
        filterSearch: true,
        onFilter: (value: any, record: any) => record?.status?.includes(value),
      },
      {
        title: "Token",
        dataIndex: "token_status",
        key: "Token",
        render: (Status: BeaconStatus, record: any) =>
          BeaconStatusIcon(Status, record.token_id, setIsRerender),
        width: 70,
        filters: getFilterOptions([
          ...defaultFilter,
          "",
          ...state.tabledata
            ?.map((item: any) => item?.token_status)
            ?.filter(onlyUnique),
        ]),
        filterSearch: true,
        onFilter: (value: any, record: any) =>
          customNotIncludesFilter(
            record?.token_status,
            value,
            NetcatStore.storeState.getState().filteredInfo?.Token
          ),
      },
      {
        title: "In use",
        dataIndex: "in_use",
        key: "in_use",
        width: 60,
        render: (in_use: boolean) =>
          in_use && (
            <CheckOutlined style={{ color: "green", fontSize: "19px" }} />
          ),
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
        width: 55,
        render: (value: string) => renderSessionsIcons([value]),
        filters: getFilterOptions([
          ...defaultFilter,
          ...state.tabledata
            ?.map((item: any) => item.type)
            ?.filter(onlyUnique)
            .sort(),
        ]),
        filterSearch: true,
        onFilter: (value: any, record: any) =>
          customNotIncludesFilter(
            record?.type,
            value,
            NetcatStore.storeState.getState().filteredInfo?.type
          ),
      },
      {
        title: "Version",
        dataIndex: "version",
        key: "version",
        width: 95,
        filters: getFilterOptions(
          state.tabledata
            ?.map((item: any) => item.version)
            ?.filter(onlyUnique)
            .sort()
        ),
        filterSearch: true,
        onFilter: (value: any, record: any) => record.version.includes(value),
        sorter: (a: any, b: any) => columnSorter(a.version, b.version),
      },
      {
        title: "GW",
        dataIndex: "gateway_address",
        key: "gateway_address",
        width: 110,
        filters: getFilterOptions([
          ...defaultFilter,
          "",
          ...state.tabledata
            ?.map((item: any) => item?.gateway_address)
            ?.filter(onlyUnique)
            .sort(),
        ]),
        filterSearch: true,
        onFilter: (value: any, record: any) => {
          return customNotIncludesFilter(
            record?.gateway_address,
            value,
            NetcatStore.storeState.getState().filteredInfo?.gateway_address
          );
        },
      },
      roles?.includes("all-sessions")
        ? {
            title: "Client",
            dataIndex: "target_host",
            key: "client",
            width: "6%",
            filters: client_filters,
            filterSearch: true,
            render: (value: any) => <>{value.client}</>,
            onFilter: onClientFilter,
            sorter: (a: any, b: any) =>
              columnSorter(a.target_host.client, b.target_host.client),
          }
        : null,
      colsType === undefined ||
      colsType === "targetInfo" ||
      colsType === "binaryInfo"
        ? {
            title: "Host name",
            dataIndex: "target_host",
            key: "HostName",
            render: (v: any, r: any) =>
              host_link(v.hostname, r.target_host.target_host_id),
            sorter: (a: any, b: any) =>
              columnSorter(a.target_host.hostname, b.target_host.hostname),
          }
        : null,
      colsType === undefined || colsType !== "targetInfo"
        ? {
            title: "Target",
            dataIndex: "target_host",
            key: "target",
            render: (value: any, record: any) =>
              target_link(value.target, record.target_host, targetsList),
            sorter: (a: any, b: any) =>
              sorter(a.target_host.target, b.target_host.target),
          }
        : null,
      {
        title: "Relay",
        dataIndex: "relay_addr",
        key: "relay_addr",
        render: (_, record: any) => render_relay(record),
        sorter: (a: any, b: any) => columnSorter(a.relay_addr, b.relay_addr),
        filters: getFilterOptions([
          ...defaultFilter,
          ...state.tabledata
            ?.map((item: any) => item.relay_addr.split(":")[0])
            ?.filter(onlyUnique)
            .sort(),
        ]),
        filterSearch: true,
        onFilter: (value: any, record: any) =>
          customNotIncludesFilter(
            record?.relay_addr.split(":")[0],
            value,
            NetcatStore.storeState.getState().filteredInfo?.relay_addr
          ),
      },
      colsType === undefined ||
      colsType === "targetInfo" ||
      colsType === "binaryInfo"
        ? {
            title: "Target IP",
            dataIndex: "target_host",
            key: "ClientIp",
            render: (value: any) => <>{value.external_ip}</>,
            filters: getFilterOptions(
              state.tabledata
                ?.map((item: any) => item.target_host.external_ip)
                ?.filter(onlyUnique)
            ),
            filterSearch: true,
            onFilter: (value: any, record: any) =>
              record.target_host.external_ip.includes(value),
            sorter: (a: any, b: any) =>
              columnSorter(
                a.target_host.external_ip,
                b.target_host.external_ip
              ),
          }
        : null,
      {
        title: "Tags",
        dataIndex: "tags",
        key: "tags",
        filters: sortOptions(
          getFilterOptions(
            state.tabledata?.flatMap((x) => x.tags)?.filter(onlyUnique)
          )
        ),
        filterSearch: true,
        render: (value: string[]) => render_tags(value),
        onFilter: (value: any, record: any) => record.tags?.includes(value),
      },
      {
        title: "First seen",
        dataIndex: "created_at",
        key: "created_at",
        filters: getDateFilterOptions(),
        render: (created_at: any) => renderDate(created_at.Time),
        sorter: (a: any, b: any) =>
          date_sorter(a.created_at.Time, b.created_at.Time),
        onFilter: (value: any, record: any) =>
          date_filter(value, record.created_at.Time),
      },
      {
        title: "Last seen",
        dataIndex: "updated_at",
        key: "updated_at",
        filters: getDateFilterOptions(),
        onFilter: (value: any, record: any) =>
          date_filter(value, record.updated_at.Time),
        render: (updated_at: any) => renderDate(updated_at.Time),
        sorter: (a: any, b: any) =>
          date_sorter(a.updated_at.Time, b.updated_at.Time),
      },

      {
        title: "Comment",
        dataIndex: "comment",
        key: "Comment",
        width: 250,
      },
      {
        title: "Actions",
        dataIndex: "Actions",
        key: "Actions",
        render: (_text: any, record: any) =>
          render_actions(record, setIsRerender),
        width: 180,
        fixed: "right",
      },
    ] as ColumnsType<any>
  ).filter((i) => i !== null);

  return {
    columns: columns,
    column_names: columns.map((item) => item.title?.toString()) as string[],
  };
};
